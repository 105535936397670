﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />

label {
  font-size: small;
  color: $text-muted;
  display: inline-flex;
  align-items: center;
}

.form-control[readOnly] {
  background-color: $input-bg;
}

.kpi-screen {
  .form-control[readOnly] {
    background-color: $gray-200;
  }
}

div.card {
  /* Increase default space between inputs (non react-select) and their labels. */
  input.form-control {
    margin: 2px 0;
  }
  select.form-control {
    margin: 1px 0;
  }
  select.placeholder {
    color: $input-placeholder-color;
    option:not(:disabled) {
      color: $gray-800;
    }
  }
}

@mixin hover-borders($padding-y, $padding-x) {
  .form-control:not(:hover):not(:focus):not(:active):not(.is-invalid),
  .react-select__control:not(:hover):not(:focus):not(:active) {
    border-color: transparent;
  }

  input.form-control,
  textarea.form-control {
    margin: calc(-#{$padding-y} - #{$input-border-width}) calc(-#{$padding-x} - #{$input-border-width});
  }

  .follow-up-input {
    .btn-group {
      top: calc(#{$padding-y} + #{$input-border-width});
    }
  }

  .invalid-feedback {
    margin-top: 0.5em;
  }

  .inline-suggest {
    input:not(:hover):not(:focus):not(:active):not(.is-invalid) {
      border-color: transparent;
    }

    margin: calc(-#{$padding-y} - #{$input-border-width}) calc(-#{$padding-x} - #{$input-border-width});
  }
}

.inline-suggest {
  // Make a stacking context
  position: relative;
  z-index: 0;

  // Don't wrap the ghost text or let it flow outside the input
  white-space: nowrap;
  overflow: hidden;
}

.rich-text-editor {
  position: relative;
  z-index: 0; //needed to create a stacking context for overlapping with the select list
  div[class^="RichTextEditor__editor__"] {
    font-size: $font-size-base;
  }
}

.checkbox {
  display: flex;
  align-items: center;

  .icon {
    height: 1.5em;
    width: 1.5em;
    @extend .mr-1;
  }

  label {
    color: $yiq-text-dark;
  }

  &.disabled {
    .icon {
      fill: $gray-600;
    }

    label {
      color: $text-muted;
    }
  }
}

form.hover-borders {
  @include hover-borders($input-padding-y, $input-padding-x);
}

form.hover-borders-sm {
  input.form-control,
  textarea.form-control {
    @extend .form-control-sm;
  }

  @include hover-borders($input-padding-y-sm, $input-padding-x-sm);
}

.react-select--is-disabled {
  background-color: $gray-200;
}

.react-select {
  width: 100%;

  .badge {
    font-size: 100%;
  }

  .react-select__control {
    background-color: transparent;
    border-color: $input-border-color;
    border-radius: $border-radius;
    min-height: $input-height;

    &.react-select__control--is-focused {
      border: 1px solid $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
  }

  .react-select__indicator {
    padding: 6px;
  }

  .react-select__value-container {
    @extend .py-0; // correct to normal Bootstrap input height
  }

  .react-select__menu {
    border-radius: $border-radius;
  }

  .react-select__placeholder {
    color: $input-placeholder-color;
  }

  &.is-invalid {
    .react-select__control {
      border-color: $form-feedback-invalid-color !important;
      //copied from the Bootstrap form styling
      &:focus {
        border-color: $form-feedback-invalid-color !important;
        box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25) !important;
      }
    }

    ~ .invalid-feedback,
    ~ .invalid-tooltip {
      display: block;
    }
  }

  &.hide-cursor {
    input {
      color: transparent !important;
    }
  }

  .react-select__multi-value__remove {
    &:hover {
      color: $yiq-text-dark;
      background-color: theme-color-level("danger", $alert-bg-level);
    }
  }

  &.no-dropdown {
    .react-select__indicators {
      display: none;
    }
  }

  &.no-menu {
    .react-select__menu {
      display: none;
    }
  }
}

input.dim-date:not(:focus) {
  &::-webkit-datetime-edit-text, /* this makes the slashes in dd/mm/yyyy grey */
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-year-field {
    color: $input-placeholder-color;
  }
}

.input-group > .form-control.rounded-right-override,
.input-group > .input-group-append.rounded-right-override > .input-group-text {
  // Workaround rule for a limitation of Bootstrap 4 with input groups with validation messages.
  @include border-right-radius($border-radius);
}

.card-body > .form-group:last-child {
  @extend .mb-0;
}

.form-control-plaintext .badge {
  font-size: inherit;
}

.form-check-label {
  display: flex;
  align-items: flex-start;
  color: $yiq-text-dark;
}

.gray-form-section {
  padding-top: 1em;
  background-color: $gray-100;
  border-top: 3px solid $gray-400;
  margin-bottom: 1em;
  p.form-section-header {
    color: $black;
    font-size: 1.1em;
    font-weight: 500;
    &.no-margin-bottom {
      margin-bottom: 0;
    }
  }
  p.subheading {
    color: $black;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: $spacer * 0.5;
  }
  .react-select {
    background-color: white;
    &.react-select--is-disabled {
      background-color: $gray-200;
    }
  }
}

.form-group-wrapper {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0 6px;
  align-items: flex-start;
}

.form-group-input-wrapper {
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  gap: 0 6px;
  align-items: center;
}

.radio-group-wrapper {
  display: flex;
  gap: 20px;
  margin: 5px;
}

.btn-outline-danger {
  &.btn:not(:hover) {
    .icon {
      fill: $danger;
    }
  }
}

fieldset legend {
  width: 0;
}
