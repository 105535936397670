﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />
/// <reference path=".\_common.scss" />

.application-screen {
   .add-strategy-command {
      display: flex;
      justify-content: flex-end;
      @extend .mb-3;
   }
}

.strategy {
   .strategy-section-title {
      @extend .mb-3;
      font-size: $input-font-size-lg;
      font-family: $headings-font-family;
      font-weight: 500;
      display: flex;
      align-items: baseline;
      text-transform: uppercase;

      &.collapsed {
         @extend .mb-0;
      }

      form {
         flex-grow: 1000;
      }

      .strategy-name {
         text-transform: none;
         font-weight: 500;
         @extend .ml-4;

         .form-control {
            font-weight: 500;

            &.empty:not(:hover):not(:focus):not(:active):not(.is-invalid) {
               border-color: $input-focus-border-color;
            }
         }

         @include media-breakpoint-down(xs) {
            margin-left: unset !important;
            margin-top: $spacer;
         }
      }

      .gap {
         flex-grow: 1;
      }

      .delete-button {
         @include hover-button($font-size-base * 1.25, theme-color-level("danger", $alert-bg-level));
         align-self: flex-start;
         display: flex;
      }
   }

   .subheading {
      font-size: $h5-font-size;
      font-weight: 500;
      @extend .py-2;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid $gray-200;

      .gap {
         flex-grow: 1;
      }

      .commands {
         > *:not(:last-child) {
            @extend .mr-1;
         }
      }
   }

   .subsubheading {
      font-size: 1.1em;
      font-weight: 500;
      @extend .mb-1;
      @extend .mt-3;
      display: flex;
      align-items: center;
   }

   .card {
      :not(.collapsed) {
         .delete-button {
            display: flex;
         }
      }
   }

   .card.collapsed {
      border: none;
      @extend .p-0;
      @extend .m-0;
      border-bottom: 1px solid $gray-200;
   }

   .collapse {
      > .form-group:last-child {
         margin-bottom: 0;
      }
   }

   .name-and-badges {
      margin-right: auto;

      .name {
         font-size: 1.1em;
      }
   }

   .badges {
      display: flex;
      flex-wrap: wrap;

      > :not(:last-child) {
         @extend .mr-1;
      }
   }

   .card-title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      cursor: pointer;
      font-family: $font-family-base;
      font-size: $font-size-base;
      text-transform: none;
      font-weight: 400;
      @extend .py-0;

      .collapse-toggle {
         flex-grow: 1;
      }

      .collapse-icon {
         font-size: 1.2em;
      }

      &.collapsed {
         @extend .m-0;
         @extend .py-2;
      }

      .delete-button {
         @include hover-button($font-size-base * 1.25, theme-color-level("danger", $alert-bg-level));
         @extend .ml-5;
         display: none;
         align-self: flex-start;
      }
   }

   .failure-mode-section {
      @extend .mb-4;
   }

   .failure-mode-status,
   .configuration-status {
      @extend .ml-3;
   }

   .risk-option {
      display: flex;
      align-items: center;

      .swatch {
         border-radius: 50%;
         height: 1em;
         width: 1em;
         @extend .mr-2;

         &.red {
            background-color: $red;
         }

         &.orange {
            background-color: $orange;
         }

         &.yellow {
            background-color: $yellow;
         }

         &.green {
            background-color: $green;
         }
      }
   }

   .mitigation-type-badge {
      display: inline-block;
      width: 1em;
      height: 1em;
      @extend .mr-2;
   }

   .header {
      font-weight: bold;
   }

   .recorded-failures {
      .failures {
         display: table;

         .tag {
            @extend .pr-3;
         }

         > * {
            display: table-row;

            > * {
               display: table-cell;
            }
         }

         .date {
            @extend .mr-1;
         }
      }
   }

   .mitigation {
      .checkbox {
         display: flex;
         align-items: flex-start;
      }
   }
}

.mitigation-implementations-table {
   border: none;

   .origin-cell {
      border: none;
   }

   thead th {
      border-bottom: none;
   }

   .mitigation-name {
      writing-mode: vertical-rl;
      white-space: nowrap;
      max-height: 16em;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   width: auto;
   @extend .m-0;

   th.mitigation {
      border-top-width: 0.5em;
      border-top-style: solid;
      @extend .py-2;

      &.alert {
         border-top-color: $alert-mitigation;
      }

      &.diagnostic {
         border-top-color: $diagnostic-mitigation;
      }

      &.field-work {
         border-top-color: $field-work-mitigation;
      }

      &.proactive {
         border-top-color: $proactive-mitigation;
      }
   }

   td.implementation {
      text-align: center;

      &.clickable {
         cursor: pointer;
      }
   }
}

.application-list {
   .screen-title {
      @extend .mb-3;
   }

   .other-sites-checkbox {
      @extend .mb-3;
   }
}
