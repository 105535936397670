.outage-form-dollar-amount-input {
   height: 31px;
   margin-top: 2px;
}
.outage-form-dollar-amount-input + input::-webkit-outer-spin-button,
.outage-form-dollar-amount-input + input::-webkit-inner-spin-button {
 -webkit-appearance: none;
  margin: 0;
}
.outage-form-dollar-amount-input + input[type=number] {
   -moz-appearance: textfield;
}

.outage-form-lead-time-input {
   display: flex;

   .lead-time-number-input.form-control {
      width: 50px;
      margin-right: 4px;
   }
   .lead-time-unit-input.form-control {
      margin: auto;
      width: 100%;
   }
}

.outage-detail-form {
   .form-group {
      margin-bottom: 0;
   }
}

.additional-details-toggle-button-wrapper {
   display: flex;
   justify-content: flex-end;
   width: 100%;
   height: 100%;
   align-items: flex-end;

   button {
      background: transparent;
      border: none;
      outline: none;

      color: $spartan-blue;

      svg {
         fill: $spartan-blue;
      }
   }
}

.remove-secondary-scope-button-wrapper {
   @extend .additional-details-toggle-button-wrapper;
   button {
      color: $spartan-blue;
      svg {
         fill: $spartan-blue;
         margin-right: 4px;
      }
   }
}

.add-secondary-scope-button-wrapper {
   @extend .additional-details-toggle-button-wrapper;
   button {
      color: $spartan-blue;
      svg {
         fill: $spartan-blue;
         margin-right: 4px;
         margin-bottom: 3px; // for some reason the add icon doesn't line up with text when centered.
      }
   }
}

.form-error {
   width: 100%;
   margin-top: 0.25rem;
   font-size: 80%;
   color: #dc3545;
}
