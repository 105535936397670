﻿/// <reference path="../../../../node_modules/bootstrap/scss/bootstrap.scss" />

$spartan-blue: #004b8d;
$spartan-grey: #939598;
$blue: $spartan-blue;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-450: #b5b5b5 !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$brown: #684326;

$dark: $gray-800 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
   (
      "blue": $blue,
      "indigo": $indigo,
      "purple": $purple,
      "pink": $pink,
      "red": $red,
      "orange": $orange,
      "yellow": $yellow,
      "green": $green,
      "teal": $teal,
      "cyan": $cyan,
      "white": $white,
      "gray": $gray-600,
      "gray-dark": $gray-800,
      "brown": $brown,
   ),
   $colors
);

$control-valve: darken($blue, 5%);
$isolation-valve: darken($green, 20%);
$manual-valve: darken($red, 30%);
$relief-valve: darken($brown, 5%);
$regulator: darken($pink, 35%);
$measurement: $dark;
$machinery: darken($purple, 25%);

$asset-type-colors: (
   "control-valve": $control-valve,
   "isolation-valve": $isolation-valve,
   "manual-valve": $manual-valve,
   "relief-valve": $relief-valve,
   "regulator": $regulator,
   "measurement": $measurement,
   "machinery": $machinery,
);

$reactive: #8c0e0e;
$preventative: #892f0f;
$predictive: #688c0e;
$proactive: #0e8c8c;
$c-and-su: #0e238c;
$activity: #680e8c;
$valve-diagnostic: #0e8c18;
$field-inspection: #8c7b0e;
$outage-scope: #3d8be7;

$status-in-progress: #3182ce;
$status-completed: #38a169;

$record-type-colors: (
   "reactive": $reactive,
   "preventative": $preventative,
   "predictive": $predictive,
   "proactive": $proactive,
   "c-and-su": $c-and-su,
   "activity": $activity,
   "valve-diagnostic": $valve-diagnostic,
   "field-inspection": $field-inspection,
   "outage-scope": $outage-scope,
);

$alert-mitigation: darken($red, 20%);
$diagnostic-mitigation: $brown;
$field-work-mitigation: darken($green, 10%);
$proactive-mitigation: darken($proactive, 10%);

$mitigation-type-colors: (
   "alert-mitigation": $alert-mitigation,
   "diagnostic-mitigation": $diagnostic-mitigation,
   "field-work-mitigation": $field-work-mitigation,
   "proactive-mitigation": $proactive,
);

$status-identified: lighten($spartan-blue, 25%);
$status-complete: lighten($green, 10%);
$status-pending-customer-action: darken($yellow, 5%);
$status-pending-spartan-action: $red;
$status-archived: lighten($gray-600, 5%);
$status-open: darken($orange, 5%);

$status-colors: (
   "identified": $status-identified,
   "complete": $status-complete,
   "pending-customer-action": $status-pending-customer-action,
   "pending-spartan-action": $status-pending-spartan-action,
   "archived": $status-archived,
   "open": $status-open,
);

// Perferring system fonts improves performance and the fonts just look nicer. A lot of front end of
// frameworks are moving in this direction anyway.
//
// https://medium.com/needmore-notes/using-system-fonts-for-web-apps-bf76d214a0e0
$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
   "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
   "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$headings-font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
   "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-size-base: 0.875rem;

$body-bg: #f8f8f8;

$enable-rounded: false;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$label-margin-bottom: 0;
$input-color: $gray-800 !default;
$input-padding-y: 0.25rem;
$input-padding-x: 0.25rem;
$input-focus-box-shadow: none;
$input-placeholder-color: $gray-500;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
   (
      0: 0,
      1: (
         $spacer * 0.25,
      ),
      2: (
         $spacer * 0.5,
      ),
      3: $spacer,
      4: (
         $spacer * 1.5,
      ),
      5: (
         $spacer * 3,
      ),
      -1: (
            $spacer * -0.25,
         ),
      -2: (
            $spacer * -0.5,
         ),
      -3: (
            $spacer * -1,
         ),
   ),
   $spacers
);
$grid-gutter-width: $spacer;

$popover-max-width: 30rem;

$card-spacer-y: 0.5rem;
$card-spacer-x: 1rem;
