﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path="./_variable-overrides.scss" />

$site-header-height: 127px;
$site-header-height-mobile: 127px;

/// Offset so that save indicator does not display over any buttons
$save-indicator-right: 11.5rem;
$save-indicator-gap: 0.3rem;
$retry-button-offset: 2.6rem;

html,
body,
#react-app {
   min-height: 100vh;
}

#react-app {
   display: flex;
   flex-direction: column;
}

.app-loading {
   @extend .p-3;
}

.site-header {
   background-color: $white;
   position: sticky;
   top: 0;
   padding: 0 2%;
   z-index: 200;
   box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

   @include media-breakpoint-down(xs) {
      padding: 0 2px !important;
   }

   .site-header-global {
      color: $white;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: $headings-font-family;

      a.logo-title {
         display: flex;
         flex-grow: 0;
         flex-shrink: 0;
         align-items: center;
         color: $white;
         @extend .mr-5;
         overflow: hidden;

         &:hover,
         &:active {
            text-decoration: none;
         }

         $logo-title-height: $site-header-height * 0.7;
         height: $logo-title-height;

         @include media-breakpoint-down(md) {
            margin-right: $spacer !important;
         }

         @include media-breakpoint-down(sm) {
            margin-right: $spacer !important;
         }

         @include media-breakpoint-down(xs) {
            margin-right: 0 !important;
         }

         > * {
            flex-grow: 0;
            flex-shrink: 0;
         }
      }

      .logo {
         height: 60px;
         width: auto;
         padding: 8px;

         &.mobile {
            display: none;
            width: 55px;
         }

         @include media-breakpoint-down(sm) {
            // Show only the circular part of the logo
            display: none;
            &.mobile {
               display: block;
            }
         }
      }
   }

   .site-header-site {
      height: 40px;
      .site-menu {
         display: flex;
         gap: 1em;
         .react-select {
            border-color: #808080;
            border-radius: 1;
            height: 30px;

            &.select-customer {
               max-width: 300px;
            }
            &.select-site {
               max-width: 375px;
            }

            .react-select__menu {
               margin-top: 0;
            }
            .react-select__input-container {
               padding: 0;
               margin: 0;
            }
         }
      }
   }

   .title-subtitle {
      $title-size: 3.5em;
      font-size: $title-size * 0.8;
      font-weight: 500;

      @include media-breakpoint-down(lg) {
         font-size: $title-size * 0.6;
      }

      @include media-breakpoint-down(md) {
         font-size: $title-size * 0.5;
      }

      @include media-breakpoint-down(sm) {
         display: none;
      }

      .title,
      .subtitle {
         white-space: nowrap;
      }

      .title {
         line-height: 1;
         text-transform: uppercase;
      }

      .subtitle {
         font-size: 0.28em;
      }
   }

   .header-search-bar,
   .header-links {
      flex-shrink: 1;
      flex-grow: 1;
   }

   @include media-breakpoint-down(xs) {
      .header-links {
         display: none;
      }
   }

   .mobile-site {
      font-family: $font-family-base;
      font-size: 0.6rem;
      @extend .mb-1;
   }

   .header-menu,
   .user-menu {
      margin-left: 0 !important;
      font-family: $font-family-base;

      .menu-toggle {
         font-size: $h1-font-size;
         cursor: pointer;

         .icon {
            fill: $white;
         }

         @include media-breakpoint-down(md) {
            font-size: $h3-font-size;
         }
      }

      .dropdown-item {
         display: flex;
         align-items: center;

         .icon {
            @extend .mr-2;
            font-size: $h4-font-size;
         }

         &:active {
            .icon {
               fill: $white;
            }
         }
      }

      a {
         display: block;
      }
   }

   .header-menu {
      @extend .ml-3;

      .btn {
         background-color: transparent;
         border-color: transparent;

         &:hover:not(.disabled),
         &:active:not(.disabled),
         &:focus:not(.disabled) {
            border-color: $white;
         }

         &:focus,
         &:active,
         &:focus:active {
            box-shadow: none;
         }

         &:active {
            background-color: transparent;
         }

         &.disabled {
            opacity: 1;
         }
      }

      .current-context {
         text-align: left;
         @extend .mr-2;
         white-space: nowrap;
         font-weight: 500;
         color: $white;
      }

      .icon {
         @extend .ml-2;
      }
   }

   .site-menu {
      @include media-breakpoint-down(sm) {
         display: none;
      }
   }

   .business-area-menu {
      @include media-breakpoint-down(md) {
         display: none;
      }
   }

   .menus {
      flex-grow: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
   }

   .user-menu-toggle {
      $size: $font-size-base * 3.3;

      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: none;

      .avatar {
         height: $size;
         width: $size;
      }

      .icon {
         fill: white;
         position: absolute;
         right: -$size * 0.25;
         display: none;
         font-size: large;

         &.show {
            display: block;
         }
      }

      &:hover {
         .icon {
            display: block;
         }
      }
   }

   .mobile-nav-toggle {
      display: none;
      margin: 0 0 0 5px;

      @include media-breakpoint-down(xs) {
         display: block;
      }

      border: none;
      color: white;
      background-color: transparent;
      height: 100%;
      padding-left: 1em;
      padding-right: 1em;

      svg {
         fill: $dark;
         height: 1.25em;
         width: 1.25em;
      }
   }
}

.save-indicator {
   position: fixed;
   top: calc(#{$site-header-height} + #{$spacer});
   right: $save-indicator-right;
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   z-index: 100;
   color: $spartan-blue;
   font-family: $font-family-base;
   font-size: $font-size-base * 1.2;
   font-weight: 500;
   @extend .text-uppercase;
   transition: all 0.5s ease;
   border: 1px solid $gray-200;
   background: $white;

   &.hidden {
      opacity: 0;
      z-index: -1;
   }

   .save-indicator-message {
      @extend .px-1;
   }

   &.failed {
      color: $danger;
   }

   @include media-breakpoint-down(xs) {
      right: 0;
   }
}

.nav-and-content {
   display: flex;
   flex-grow: 1;

   .nav {
      @include media-breakpoint-down(xs) {
         display: none;
      }
   }
}

.mobile-nav-collapse {
   position: absolute;
   top: $site-header-height;
   width: 100%;
}

.nav {
   $button-hover-color: rgba(0, 0, 0, 0.1);
   $button-active-color: rgba(0, 0, 0, 0.2);
   flex-grow: 0;
   flex-shrink: 0;
   flex-wrap: nowrap;
   width: 40px;
   display: flex;
   flex-direction: column;
   position: sticky;
   top: $site-header-height;
   z-index: 1;

   &.expanded {
      /*fit-content is not supported by IE/Edge, so we need to specify a width to fall back on.*/
      width: 20em;
      width: fit-content;
   }

   transition: width cubic-bezier(0.4, 0, 0.2, 1) 200ms;
   background-color: $white;
   border-right: 1px solid $gray-400;
   border-bottom: 1px solid $gray-400;

   &.mobile {
      width: 100%;

      .site-menu {
         > button {
            padding: 0 !important;
         }

         .current-context-wrapper {
            display: flex;
            margin-left: 1em;
         }

         .site-menu-spacer {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            margin-left: 1em;
         }

         .site-menu-dropdown-wrapper {
            display: flex;
            align-items: center;
         }

         .current-context {
            text-align: left;
            @extend .mr-2;
            white-space: nowrap;
            font-weight: 500;
         }
      }
   }

   .icon {
      fill: $gray-800;
   }

   .gap {
      flex-grow: 1;
      flex-shrink: 1;
   }

   .nav-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      @extend .py-3;
      @extend .px-0;
      background-color: transparent;
      border: none;

      &:hover {
         background-color: $button-hover-color;
      }

      &:active {
         background-color: $button-active-color;
      }

      &:focus {
         outline: none;
      }
   }

   .nav-expand-icon {
      transition: transform 500ms ease;
      font-size: 1.5rem;

      &.expanded {
         transform: rotate(180deg);
      }
   }

   .main-menu {
      width: 100%;
      @extend .p-3;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      font-size: $font-size-base;

      .main-menu-group {
         @extend .mb-3;

         .main-menu-item {
            display: block;
            @extend .ml-4;
            color: $yiq-text-dark;
            @extend .p-0;
         }

         .main-menu-item:first-child {
            @extend .mt-1;
         }

         .main-menu-item:not(:last-child) {
            @extend .mb-2;
         }

         &.sign-out-menu-group {
            @extend .mt-3;
         }

         &.sign-out-menu-group {
            @extend .mb-0;
         }
      }

      .main-menu-group-header {
         font-family: $headings-font-family;
         font-size: 1.4em;
      }

      .dropdown {
         font-size: 1.4em;

         .menu-toggle {
            display: flex;
            justify-content: center;
            cursor: pointer;
            @extend .py-3;

            &:hover {
               background-color: $button-hover-color;
            }

            &:active {
               background-color: $button-active-color;
            }
         }
      }

      .dropdown-menu {
         .dropdown-item {
            display: flex;
            align-items: center;

            .icon {
               @extend .mr-2;
            }
         }
      }

      .screen-link,
      .main-menu-group-header {
         display: flex;
         align-items: center;

         .icon {
            @extend .mr-2;
         }
      }

      a {
         color: $yiq-text-dark;
      }

      &.contracted {
         @extend .px-0;
         align-items: stretch;
         font-size: $font-size-base * 1.25;
         width: 100%;

         .main-menu-group {
            @extend .m-0;

            > * {
               @extend .m-0;
            }
         }

         .main-menu-group-header {
            @extend .py-3;
            display: flex;
            justify-content: center;

            > .icon {
               @extend .mr-0;
            }
         }

         .tutorial-header {
            &:hover {
               background-color: $button-hover-color;
               cursor: pointer;
            }
         }

         a:hover {
            background-color: $button-hover-color;
         }

         a:active {
            background-color: $button-active-color;
         }

         > *:not(:last-child) {
            @extend .mb-0;
         }
      }

      .collapse-icon {
         transform: rotate(-90deg);
      }
   }
}

$content-top-padding: $spacer;

.content-body-top-buffer {
   // This extra wrapper div is here so that when the body scrolls,
   // the content doesn't appear in the narrow gap between the site header and the fixed screen headers.
   padding-top: $content-top-padding;
   flex-grow: 1;
   flex-shrink: 1;
   height: calc(100vh - 100px);
   overflow-x: hidden;
}

.content-body {
   // make the body a stacking context
   position: relative;
   z-index: 0;
   overflow-y: auto;
   max-height: calc(100vh - (100px + #{$content-top-padding}));
   min-height: calc(100vh - (100px + #{$content-top-padding}));

   &.horizontal-scrolling {
      overflow-x: scroll;
   }

   @extend .px-3;
   @extend .pb-3;
}

.left-drop-icon {
   margin-left: auto;
   transform: rotate(-90deg);
}

.site-menu-dropdown {
   background-color: $white;
   border: solid 1px;
   border-color: rgba(0, 0, 0, 0.15);
   cursor: default;
   padding-top: $spacer * 0.5;
   padding-bottom: $spacer * 0.5;
   max-height: 90vh;
   overflow: auto;

   .menu-item {
      :hover {
         background-color: $light;
      }

      .menu-item-text {
         display: flex;
         align-items: center;
         padding-top: $spacer * 0.5;
         padding-bottom: $spacer * 0.5;
         padding-left: $spacer;
         padding-right: $spacer;

         .menu-item-content {
            @extend .mr-1;
         }
      }
   }
}

.tutorial-menu {
   .collapse,
   .collapsing {
      padding-left: 15px;
   }

   .tutorial-menu-item,
   .dropdown-item {
      border: none;
      outline: none;
      text-align: left;

      .btn {
         color: $yiq-text-dark;
      }

      .menu-item-text {
         display: flex;
      }
   }

   .collapse-icon {
      transform: rotate(-90deg);
   }
}

.retry-save-button {
   position: fixed;
   top: calc(#{$site-header-height} + #{$spacer + $save-indicator-gap + $retry-button-offset});
   right: $save-indicator-right;
   background-color: $danger;
   z-index: 1;
}
