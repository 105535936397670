﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />

.stacking-context {
   position: relative;
   z-index: 0;
}

@mixin hover-button($size, $bg-color: darken(white, 20)) {
   align-items: center;
   justify-content: center;
   height: $size;
   width: $size;
   border-radius: 50%;
   background-color: white;
   display: none;
   flex-shrink: 0;
   padding: 0;
   border: none;

   &:hover:not(:disabled) {
      background-color: $bg-color;
   }

   svg {
      fill: $yiq-text-dark;
      height: $size * 0.7;
      width: $size * 0.7;
   }
}

.screen-title {
   font-size: $h1-font-size * 1.2;
   line-height: 1;
   font-family: $headings-font-family;
   font-weight: 400;
   display: flex;
   flex-wrap: wrap;
   align-items: center;

   @include media-breakpoint-down(xs) {
      font-size: $h2-font-size;
   }

   form.hover-borders {
      .form-control {
         font-size: inherit;
         font-weight: inherit;
         font-style: inherit;
         font-family: inherit;
         line-height: inherit;
         height: auto;
      }
   }

   .stack {
      width: 100%;

      .subtitle {
         font-size: $font-size-base;
         font-family: $font-family-base;

         > *:not(:last-child) {
            @extend .mr-4;
         }
      }

      .invalid-feedback {
         font-size: $font-size-lg;
         font-family: $font-family-base;
      }
   }

   .icon-and-title {
      flex-shrink: 1;
      flex-grow: 1;
      display: flex;
      align-items: flex-start;

      > :first-child {
         flex-shrink: 0;
         flex-grow: 0;
         flex-basis: 1em;
      }

      > :last-child {
         flex-shrink: 1;
         flex-grow: 1;
      }
   }

   .icon {
      fill: $gray-800;
      @extend .mr-2;
   }
}

.screen-commands {
   display: grid;
   grid-gap: $spacer * 0.25;
   @extend .mb-3;

   @include media-breakpoint-down(xs) {
      grid-auto-flow: column;
   }
}

.section {
   @extend .mb-4;

   &.collapsed {
      @extend .mb-3;
   }

   .section-title {
      @extend .mb-2;
   }

   .emphasize {
      font-size: $font-size-base;
   }
}

.section-title {
   display: flex;
   flex-wrap: wrap;
   align-items: center;

   .title {
      text-transform: uppercase;
      font-size: $h5-font-size;
      font-family: $headings-font-family;
      font-weight: 500;
   }

   .gap {
      flex-grow: 1;
   }

   > :not(:last-child) {
      @extend .mr-2;
   }

   .collapse-icon + .title {
      @extend .ml-0;
   }
}

.tag {
   font-family: Inconsolata, monospace;
   font-size: 1.15em;
   white-space: nowrap;
}

.collapse-icon {
   transition: transform 0.3s ease;

   &.collapsed {
      transform: rotate(180deg);
   }
}

.collapse-toggle {
   cursor: pointer;
   display: flex;
   align-items: center;
   border: 1px solid transparent;
   border-radius: $border-radius;

   .collapse-icon {
      fill: $gray-600;
      @extend .mr-1;
   }

   &.collapsed {
      @extend .mb-0;
   }
}

.archived-icon {
   transform: rotate(180deg);
}

.badge {
   font-weight: 700;
   display: inline-flex;
   align-items: center;

   .icon:first-child {
      @extend .mr-1;
   }
}

@each $color, $value in $theme-colors {
   .badge-#{$color} .icon {
      fill: color-yiq($value);
   }
}

@each $color, $value in $colors {
   .badge-#{$color} {
      @include badge-variant($value);

      .icon {
         fill: color-yiq($value);
      }
   }
}

@mixin outline-badge-variant($color) {
   color: $color;
   background-color: white;
   border: 0.08em solid $color;
}

@each $color, $value in $asset-type-colors {
   .badge-#{$color} {
      @include badge-variant($value);

      .icon {
         fill: color-yiq($value);
      }
   }
}

@each $color, $value in $record-type-colors {
   .badge-#{$color} {
      @include outline-badge-variant($value);

      .icon {
         fill: color-yiq($value);
      }
   }
}

@each $color, $value in $mitigation-type-colors {
   .badge-#{$color} {
      @include badge-variant($value);
   }
}

@each $color-name, $color-value in $status-colors {
   .badge-#{$color-name} {
      @include badge-variant(lighten($color-value, 42%));
      color: darken($color-value, 30%);

      .icon {
         fill: darken($color-value, 30%);
      }
   }
}

.attachment-count {
   align-items: baseline;
   color: $gray-700;
}

.avatar {
   border-radius: 50%;
   height: 100%;
   width: 100%;

   &.missing-avatar {
      background: #ddd;
      color: #676767;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
         line-height: 1;
         font-size: 10pt;
         font-weight: bold;
         padding-top: 2px;
      }
   }
}

.text-small {
   font-size: small;
}

.list-group {
   // Make list groups have their own stacking context. This fixes an issue where react-select menus
   // appear behind the active list group item.
   position: relative;
   z-index: 0;
}

.filter-row {
   $base-width: 18em;

   display: flex;
   flex-wrap: wrap;

   .form-group {
      width: $base-width;

      &.double-width {
         width: calc((2 * #{$base-width}) + #{$spacer});
      }
   }

   > :not(:last-child) {
      @extend .mr-3;
   }

   input:not(:last-child) {
      @extend .mb-1;
   }
}

.follow-up {
   &.overdue {
      color: theme-color("danger");
      font-weight: 700;
   }

   &.today {
      color: theme-color("success");
      font-weight: 700;
   }
}

.extra-tags-indicator {
   color: $text-muted;
   font-style: italic;
}

.spinner {
   @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: none;
   }
}

.spinner-ie {
   display: none;

   @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: inline;
   }
}

.keywords {
   .react-select__placeholder {
      font-size: small;
   }
}

.ordered-item {
   &:not(:last-child) {
      @extend .mb-4;
   }

   display: flex;
   width: 100%;
   $button-size: 1.5rem;

   .priority-container {
      display: flex;
      flex-direction: column;
      @extend .mr-2;
      align-items: center;
      @extend .pt-1;

      .priority {
         height: 1.5rem;
         width: 1.5rem;
         flex-shrink: 0;
         border-radius: 50%;
         display: flex;
         align-items: center;
         justify-content: center;
         background-color: $spartan-blue;
         color: $white;
      }
   }

   .delete-button {
      @include hover-button($button-size, theme-color-level("danger", $alert-bg-level));
      display: flex;
      visibility: hidden;

      @include media-breakpoint-down(xs) {
         visibility: visible;
      }
   }

   .reorder-button {
      @include hover-button($button-size);
      display: flex;
      visibility: hidden;

      @include media-breakpoint-down(xs) {
         visibility: visible;
      }
   }

   .button-container {
      display: flex;

      > :not(:last-child) {
         @extend .mr-1;
      }
   }

   &:hover {
      .delete-button,
      .reorder-button {
         visibility: visible;
      }
   }
}

.infrequent-commands {
   width: 100%;
   display: flex;
   justify-content: flex-end;
   @extend .mt-2;
}

.asset-list,
.record-list,
.event-list {
   .screen-commands {
      margin-left: auto;
      grid-auto-flow: column;

      @include media-breakpoint-down(xs) {
         margin-left: unset;
         margin-top: $spacer;
      }
   }
}

.card {
   @extend .mb-3;
}

.card-title {
   text-transform: uppercase;
   font-family: $headings-font-family;
   font-size: $h5-font-size;
   font-weight: 500;
   .no-transform {
      text-transform: none;
   }

   .btn {
      text-transform: none;
   }
}

.card-subtitle {
   font-weight: 500;
}

.modal-card-warning {
   background-color: lighten($red, 25%);
   .change,
   .item {
      margin-left: 1em;
      span {
         margin-left: 1em;
      }
   }
   .confirm-warning {
      display: flex;
      width: 100%;
      justify-content: center;
   }
}

.info-popover {
   // Needs to be at global scope since popovers are inserted at the end of the DOM
   .popover-title {
      font-weight: 500;
   }

   .popover-title:not(:first-child) {
      @extend .mt-2;
   }
}

.info-icon {
   line-height: 0;
   cursor: pointer;
   @extend .ml-1;

   .icon {
      fill: $text-muted;
   }
}

.title-card-header {
   position: sticky;
   z-index: 10;
   top: 0;
}

.btn.mobile-hidden-button {
   @include media-breakpoint-down(xs) {
      display: none;
   }
}

.mobile-only {
   @include media-breakpoint-up(sm) {
      display: none !important;
   }
}

.non-mobile-only {
   @include media-breakpoint-down(xs) {
      display: none !important;
   }
}

.no-bottom-spacing {
   &.form-group {
      margin-bottom: 0 !important;
   }
}
