﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />

.import {
   $valid-color: $green;
   $invalid-color: $red;
   $warning-color: $info;
   $row-exists-color: $spartan-blue;
   $valid-column-width: 50px;

   .screen-title {
      @extend .mb-3;
   }

   .buttons-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @extend .mb-3;

      > :not(:last-child) {
         @extend .mr-3;
      }

      > * {
         @extend .mb-3;
      }
   }

   .templates-container {
      font-family: $font-family-sans-serif;

      > :not(:last-child) {
         @extend .mr-1;
      }
   }

   .stats {
      font-weight: bold;

      .valid {
         color: $valid-color;
      }

      .row-exists {
         color: $row-exists-color;
      }

      .invalid {
         color: $invalid-color;
      }

      .warning {
         color: $warning-color;
      }
   }

   .spinner,
   .spinner-ie {
      height: 2.5rem;
   }

   .valid-icon {
      $icon-size: 1.5rem;

      .icon {
         width: $icon-size;
         height: $icon-size;
      }

      &.row-exists {
         fill: $row-exists-color;
      }

      &.invalid {
         fill: $invalid-color;
      }

      &.warning {
         fill: $warning-color;
      }

      &.valid {
         fill: $valid-color;
      }

      &.info-exists {
         fill: $info;
      }

      &.new {
         fill: $row-exists-color;
      }
   }

   .valid-header {
      width: $valid-column-width;
   }

   .table-striped {
      thead {
         .sticky-column {
            background: $white;
            z-index: 1;
         }
      }

      tbody {
         .sticky-column {
            z-index: 1;
         }

         tr:nth-of-type(odd) {

            .sticky-column {
               background: #f2f2f2;
            }
         }

         tr:nth-of-type(even) {

            .sticky-column {
               background: $white;
            }
         }
      }
   }

   .sticky-column {
      position: sticky;

      &.valid-column {
         left: 0;
      }

      &.tag-column {
         left: $valid-column-width;
      }
   }

   .invalid-message {
      color: $invalid-color;
      font-size: small;
   }

   .warning-message {
      color: $warning-color;
      font-size: small;
   }

   .edit-message {
      color: $valid-color;
      font-size: small;
   }

   .important-edit-message {
       color: $orange;
       font-size: small;
   }

   .info-message {
      color: $info;
      font-size: small;
   }

   .record-batch-table {
      .recommendations-column {
         div {
            max-height: 6em;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }
   }
}
