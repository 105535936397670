﻿.regex-example {
   font-style: italic;
}

.list-table-attachment {
   display: flex;
   justify-content: space-between;
   flex: 0;
   flex-shrink: 0;
   font-size: 140%;

   .first-column-text {
      overflow: hidden;
      font-size: small;
      flex: 1;
   }
}

.avatar-and-name {
   display: flex;
   align-items: center;

   .avatar {
      height: 2rem;
      width: 2rem;
      @extend .mr-2;
   }
}

.good {
   color: $green !important;
}

.minor {
   color: darken($yellow, 5%) !important;
}

.moderate {
   color: $orange !important;
}

.severe {
   color: $red !important;
}

.asset-selector,
.record-selector {
   .counter {
      font-weight: bold;
      font-family: $font-family-base;
      text-transform: none;

      label {
         font-weight: normal;
         @extend .mr-1;
      }
   }

   .form-control {
      @extend .mb-2;

      &.no-results {
         border: 2px solid $info;
      }
   }

   .add-buttons {
      @extend .py-2;

      .btn {
         @extend .mr-1;
         @extend .mb-1;
         display: block;
      }
   }

   .items-by-category {
      .item-group {
         &:not(:last-child) {
            @extend .mb-3;
         }

         .category {
            font-weight: 700;
         }

         .items {
            @extend .ml-2;
         }
      }
   }

   .items {
      .item {
         display: flex;
         align-items: center;
         justify-content: space-between;
         font-size: $font-size-base * 0.8;

         &:hover {
            .delete-button {
               display: flex;
            }
         }

         &.skip {
            .item-identifier {
               text-decoration: line-through;
            }
         }

         .superseded {
            font-style: italic;
            color: $info;
            @extend .ml-2;
         }

         .warning {
            color: $warning;
            font-style: italic;
            font-size: small;
            @extend .ml-2;
         }
      }

      .delete-button {
         @extend .ml-1;
         @include hover-button($font-size-base, theme-color-level("danger", $alert-bg-level));
      }

      &.valve-diagnostics {
         .item {
            display: flex;

            .item-identifier {
               flex-grow: 1;
               display: flex;
               justify-content: space-between;

               a {
                  flex-grow: 1;
                  display: flex;
                  justify-content: space-between;
               }
            }

            .delete-button {
               display: flex;
               opacity: 0;
            }

            &:hover {
               .delete-button {
                  opacity: 1;
               }
            }
         }
      }

      .item-warning {
         color: $warning;
         font-style: italic;
         @extend .ml-2;
      }

      .item-danger {
         color: $danger;
         font-style: italic;
         @extend .ml-2;
      }
   }
}

.hide-overflow {
   overflow: hidden;
}

.modal-footer {
   align-items: stretch;
}

.asset-filters, .record-filters {
   position: relative;
   z-index: 1; // Prevents dropdowns appearing behind active column header in table
   }

.asset-list, .record-list {
   .list-expandable-override {
      .react-select__menu {
         position: relative;
      }
   }
}

.line-through {
   text-decoration: line-through;
}