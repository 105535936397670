﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />

.asset-screen {
    .asset-screen-title {
        display: flex;

        .screen-title {
            flex-grow: 1;
        }

        .subtitle {
            @extend .mt-2;
            flex-wrap: wrap;

            .last-edited-details {
                @include media-breakpoint-up(lg) {
                    margin-left: auto;
                }

                @include media-breakpoint-down(lg) {                    
                    margin-top: 0.5em;
                }
            }
        }

        .application-details {
            label {
                @extend .mr-1;
            }
        }
    }

    .read-only-tag {
        display: flex;
        align-items: flex-start;

        .tag {
            padding-top: 1px;
            padding-bottom: 1px;
        }

        .btn {
            display: none;
            @extend .ml-3;
        }

        &:hover {
            .btn {
                display: block;
            }
        }
    }

    .tag-edit-form {
        position: relative;
        z-index: 0;

        .btn {
            z-index: 1;
            position: absolute;
            top: 0;
            right: calc(2 * #{$input-padding-x} + #{$input-border-width});
        }
    }

    .asset-health {
        .asset-health-spec {
            display: flex;
            align-items: center;
            justify-content: space-around;

            > :not(:last-child) {
                @extend .mr-3;
            }

            .value {
                text-align: center;
                font-size: 1.5rem;
                font-weight: bold;
            }
        }

        .card-subtitle {
            @extend .mt-3;
        }
    }

    .record-link {
        @extend .mt-3;
        line-height: 1.2;

        .record-date {
            @extend .ml-2;
        }
    }

    .footer {
        display: flex;

        .delete-asset {
            margin-left: auto;
        }
    }
}

.asset-records {
   .records {
      display: grid;
      grid-template-columns: auto auto 4fr 1fr auto;
      grid-column-gap: $spacer * 0.5;
      grid-row-gap: $spacer * 0.25;
      justify-items: start;
      align-items: center;
      overflow: hidden;

      .table-header {
         &.card-title {
            grid-column: 1 / 4;
         }
         &.savings {
            grid-column: 4 / 5;
            font-weight: 500;
            justify-self: center;
         }
         &.sort-dropdown {
            grid-column: 5 / 6;
         }
      }

      .table-footer {
         &.savings {
            grid-column: 4 / 5;
            font-weight: 500;
         }
      }

      .description {
         font-weight: 500;
         grid-column: 1 / 4;
      }

      .kpiSavings {
         grid-column: 4 / 5;
         grid-row: span 2;
         align-self: start;
         font-size: small;
      }

      .created {
         grid-column: 5 / 6;
         align-self: start;
         font-size: small;
         color: $text-muted;
         white-space: nowrap;
      }

      .record-type {
         grid-column: 1 / 2;
      }

      .status {
         grid-column: 2 / 3;
         font-size: $font-size-base * 0.8;
      }

      .details {
         grid-column: 3 / 4;
         font-size: smaller;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         width: 100%;

         > * {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }

      .attachment-count {
         grid-column: 5 / 6;
         justify-self: flex-end;
         display: flex;
         align-items: center;
      }

      .divider {
         grid-column: 1 / 6;
         border-color: $gray-200;
         @extend .my-2;
         width: 100%;

         &:first-child {
            @extend .mt-0;
         }

         &:last-child {
            @extend .mb-0;
         }
      }
   }
}

.add-record-modal {
   .btn-group {
      width: 100%;

      .btn {
         flex-grow: 1;
         display: flex;
         justify-content: space-around;
      }
   }

   .inline-suggest {
      width: 100%;
      font-size: $font-size-base;
      font-family: unset;
      line-height: 1.5;

      input {
         font-family: inherit;
         font-size: inherit;
         line-height: inherit;
         border-color: $input-border-color;
         padding: $input-padding-y $input-padding-x;
         color: $input-color;

         &:focus {
            border-color: $input-focus-border-color;
            outline: none;
         }
      }

      input + div {
         font-family: inherit;
         font-size: inherit;
         line-height: inherit;
         padding: $input-padding-y $input-padding-x;
         top: 0;
         left: 0;
         border: $input-border-width solid transparent;
      }

      &.is-invalid + .invalid-feedback {
         display: block;
      }

      &.is-invalid {
         input {
            border-color: $form-feedback-invalid-color;
         }

         .invalid-feedback {
            display: block;
         }
      }
   }
}

.valve-health-over-time {
   .chart-tooltip {
      background-color: white;
      border: 1px solid $gray-400;
      padding: 1rem;
   }

   .legend {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      @extend .mt-3;
      @extend .mb-1;

      .icon-container {
         display: flex;
         align-items: center;
         line-height: 1;

         &:not(:last-child) {
            @extend .mr-3;
         }

         .icon {
            height: 1rem;
            width: 1rem;
            @extend .mr-1;
         }
      }
   }
}

.asset-list {
   .filters .icon {
      font-size: large;
      fill: $gray-800;
   }
}
