.legacy_bootstrap {
   @import "variable-overrides";
   @import "../../../../node_modules/bootstrap/scss/bootstrap";
   @import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
   @import "../../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
   @import "../../../../node_modules/react-inline-suggest/dist/react-inline-suggest.css";
   @import "../../../../node_modules/rc-tooltip/assets/bootstrap.css";
   @import "fonts";
   @import "common";
   @import "forms";
   @import "buttons";
   @import "tables";
   @import "chrome";
   @import "search";
   @import "sites";
   @import "assets";
   @import "kpis";
   @import "records/records";
   @import "attachments";
   @import "import";
   @import "applications";
   @import "tutorials";
   @import "keywords";
   @import "common2";
   @import "summaries";

   &#bootstrap-modal-container {
      position: fixed;
      z-index: 100000;
   }
}
