.planning-scope-card {
  margin-bottom: 2rem;

  .planning-scope-card-heading {
    display: flex;
    border-top: solid 6px #c4c4c4;
    border-radius: 4px;

    .scope-title {
      font-weight: 500;
      font-size: 1rem;
      padding: 1rem 8px;
      width: 250px;
      min-width: 150px;

      @media (min-width: 900px) and (max-width: 1360px) {
        width: 200px;
      }
    }

    .scope-summary {
      width: 100%;
      display: flex;
      padding: 1rem;
      justify-content: space-between;
      background-color: #f5f5f5;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border: solid 1px #c4c4c4;
      border-top: none;

      .scope-header-column-grouping {
        display: flex;
        justify-content: space-around;
      }

      .scope-summary-item {
        display: flex;
        flex-direction: column;

        .label {
          font-weight: normal;
          font-size: 1em;
          color: $gray-600;
        }
        .value {
          font-weight: bold;
          font-size: 1.1em;
          color: $gray-700;
        }
        .subtext {
          font-weight: normal;
          font-size: 0.9em;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $gray-600;

          &.danger {
            color: darkred;
          }
        }
      }

      .scope-summary-columns {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .scope-summary-col {
          padding: 0px 8px;

          &.scope-of-work {
            width: 250px;
            padding-right: 16px;
          }
          &.quote-amount {
            width: 250px;
          }
          &.lead-time {
            width: 150px;
          }
          &.accept-button {
            width: 100px;
          }
        }

        @media (min-width: 0px) and (max-width: 900px) {
          display: flex !important;
          flex-wrap: wrap !important;
          justify-content: flex-start !important;

          .scope-summary-col {
            &.scope-of-work {
              width: 100%;
            }
            &.quote-amount {
              width: 100%;
            }
            &.lead-time {
              width: 100%;
            }
            &.accept-button {
              width: 100%;
            }
          }
        }

        @media (min-width: 900px) and (max-width: 1560px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          .scope-summary-col {
            padding: 0px 8px;
            box-sizing: border-box;

            &.scope-of-work {
              width: 100%;
              padding-right: 16px;
              padding-bottom: 16px;
            }

            &.quote-amount {
              width: 40%;
            }
            &.lead-time {
              width: 40%;
            }
            &.accept-button {
              width: 20%;
            }
          }
        }
      }
    }

    &.accepted {
      border-top-color: #c6f6d5;

      .scope-summary {
        background-color: #f5fbf8;
        border: solid 1px #c6f6d5;
        border-top: none;
      }
    }

    &.selectable {
      border-top-color: #bdcbde;

      .scope-summary {
        background-color: #edf2f7;
        border: solid 1px #bdcbde;
        border-top: none;
      }
    }

    .details-button-wrapper {
      min-width: 70px;
      display: flex;
      flex-basis: 100px;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      align-items: center;

      button {
        background: transparent;
        border: none;
        outline: none;
      }
    }

    .accept-scope-button-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }

  .spacer {
    width: 250px;

    @media (min-width: 900px) and (max-width: 1360px) {
      width: 200px;
    }

    @media (max-width: 900px) {
      width: 0px;
    }
  }

  .form-body {
    width: 100%;
  }
}
