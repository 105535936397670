﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />
/// <reference path=".\_common.scss" />
.configure-bulk-valve-health-report-modal {
   .score-range {
      display: flex;
      .form-group:not(:first-child) {
         @extend .ml-3;
      }
   }
   .selected-valve-count {
      .count {
         font-weight: bold;
      }
   }
}

.create-summary-modal {
   .alert {
      ul {
         margin-bottom: 0;
      }
   }
}

.editable-rich-text-label {
   @extend label;
}

.team-member {
   display: flex;
   align-items: center;
   font-size: $font-size-base;

   .avatar {
      $size: 2.5rem;
      height: $size;
      width: $size;
      overflow: hidden;
      @extend .mr-2;
   }
}

.configure-outage-summary-report-modal {
   form {
      > p {
         margin: 1rem 0 0 0;
      }
      h6 {
         &:first-child {
            margin: 0;
         }
         &:not(:first-child) {
            margin: 1rem 0 0 0;
         }
      }
      .form-group {
         margin-bottom: 0.25rem;
      }
      .form-check-label {
         margin-left: 2rem;
      }
      hr {
         color: $gray-700;
         border-width: 2px;
      }
   }
}
