﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />

.react-bootstrap-table {
   .table {
      th {
         font-weight: 400;
         font-size: small;
         color: $gray-700;
      }

      td {
         overflow-wrap: break-word;
      }

      .badge {
         font-size: 0.9em;
      }

      a.tag {
         display: block;
      }

      thead {

         .tag-column {
            background: $white;
            z-index: 1;
         }
      }

      tbody {
         tr:nth-of-type(odd) {

            .tag-column {
               background: #f2f2f2;
            }
         }

         tr:nth-of-type(even) {

            .tag-column {
               background: $white;
            }
         }
      }
   }

   .sort-carets {
      white-space: nowrap;
      @extend .ml-2;
      display: inline-flex;
      flex-direction: column;

      .icon {
         @extend .m--1;
      }
   }

   .collapse-icon {
      fill: $gray-500;

      &.active {
         fill: $spartan-blue;
      }
   }

   .xxl-column {
      width: 32em;
   }

   .xl-column {
      width: 24em;
   }

   .lg-column {
      width: 16em;
   }

   .md-column {
      width: 10em;
   }

   .sm-column {
      width: 8em;
   }

   .xs-column {
      width: 6em;
   }

   .xxs-column {
      width: 4em;
   }

   .tag-column {
      position: sticky;
      left: 0;
   }
}

.react-bootstrap-table {
   font-size: small;
   min-height: 40em;
}

.items-per-page {
   display: flex;

   input {
      @extend .ml-2;
      width: 5em;
   }

   @extend .my-1;
}

.pagination-top {
   // Numbers here were hand-tweaked to get the pagination controls looking as good as possible.
   padding-top: 51px;
   min-height: 100px;

   .react-bootstrap-table-pagination {
      position: absolute;
      top: 36px;
      width: 100%;
   }

   .loading {
      position: relative;
      top: 1.5em;
   }
}