﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />

.kpi-template-list {
   .screen-title {
      margin-bottom: 6px;
   }
}

.record-kpi {
   form {
      margin-top: $spacer * 0.5;
   }
   .form-control-plaintext {
      font-weight: 700;
      font-size: large;
      padding: 0;

      &.tbd {
         font-weight: 400;
         color: $text-muted;
      }

      &.emphasize {
         font-size: larger;
      }
   }

   .card-subtitle {
      @extend .mt-0;
      @extend .mb-1;
   }

   .lock-details-container {
      display: flex;

      .lock-icon-container {
         @extend .mr-1;
         display: flex;
         align-items: center;
      }
   }

   .sign-off-button-container {
      display: flex;
      justify-content: flex-end;
   }
}

.kpi-screen {
   p {
      color: $gray-600;
      margin-bottom: $spacer * 0.5;
   }

   .kpiTemplate-screen-title {
      display: flex;

      .screen-title {
         flex-grow: 1;
      }

      .subtitle {
         @extend .mt-2;
         flex-wrap: wrap;
      }
   }
   .collapse-toggle {
      display: flex;
      justify-content: space-between;
      .kpi-title {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         svg {
            margin: 0 6px 4px 0;
         }
      }
      .template-name {
         color: #6c757d;
         margin-left: 38px;
      }
      .kpi-summary {
         display: flex;
         flex-direction: column;
         align-items: flex-end;
      }
   }
   .savings-figure {
      font-weight: 700;
   }
   .signed-off {
      text-transform: uppercase;
      font-family: $headings-font-family;
      font-weight: 500;
      svg {
         margin-bottom: 4px;
      }
   }
   form {
      width: 100%;
      &.set-max-width {
         max-width: 775px;
      }
   }
   div.spacer {
      min-height: 2px;
      margin-bottom: $spacer;
      background: linear-gradient(rgba(0, 75, 141, 0.1)) bottom/ 97% 2px no-repeat;
   }
   .totals {
      text-align: right;
      div {
         margin-right: 8px;
         font-size: 1.05em;
         color: $gray-600;
         font-weight: 500;
         p {
            margin: 0;
            &.figure {
               float: right;
            }
            &.figure-light {
               float: right;
               color: $gray-500;
               font-size: 0.95em;
            }
         }
      }
   }
}

.kpi-help-icon {
   fill: #6c757d;
   line-height: 19px;
   font-size: 19px;
   margin-left: 0.3em;
   svg {
      margin-bottom: 2px;
   }
}

.kpi-tooltip-inner {
   text-align: left;
   min-width: 300px;
   font-size: 1.1em;
}

.kpiTypeBadge {
   font-size: 110%;
   font-weight: 500;
   svg.icon:first-child {
      margin-right: $spacer * 0.5 !important;
   }
}

.selectKpiTypeGroupLabel {
   color: $gray-450;
   font-size: 125%;
   text-transform: uppercase;
}

.selectKpiTypeOptionLabel {
   svg {
      margin-bottom: 4px;
   }
   span {
      margin-left: 10px;
   }
}
