﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />

.attachments {
   $delete-button-size: 1rem;
   $caption-button-size: 1.25rem;
   $rotate-button-size: 1.25rem;

   .add-button-text {
      @include media-breakpoint-down(lg) {
         display: none;
      }
   }

   .delete-button {
      @include hover-button($delete-button-size, theme-color-level("danger", $alert-bg-level));
   }

   .img-thumbnail {
      max-height: 5rem;
      image-orientation: from-image;
   }

   .pictures-container {
      display: flex;
      flex-wrap: wrap;

      .attachment-picture {
         @extend .mb-2;
         display: flex;
         position: relative;
         cursor: pointer;

         &:not(:last-child) {
            @extend .mr-1;
         }

         .figure-img {
            margin: 0;
         }

         .delete-button {
            position: absolute;
            top: calc(-#{$delete-button-size}/ 2);
            right: calc(-#{$delete-button-size}/ 2);
            z-index: 1;
            cursor: pointer;
         }

         .rotate-button {
            @include hover-button($rotate-button-size);
            position: absolute;
            top: calc(100% - #{$rotate-button-size}/ 2);
            left: calc(-#{$rotate-button-size}/ 2);
            z-index: 2;
            cursor: pointer;
         }

         .caption-button {
            @include hover-button($caption-button-size);
            position: absolute;
            top: calc(100% - #{$caption-button-size}/ 2);
            right: calc(-#{$caption-button-size}/ 2);
            z-index: 1;
            cursor: pointer;
         }

         &:hover {
            .delete-button,
            .caption-button,
            .rotate-button {
               display: flex;

               @include media-breakpoint-down(xs) {
                  display: none;
               }
            }
         }
      }
   }

   .files-container {
      .attachment-file {
         display: flex;
         align-items: center;
         @extend .mb-3;

         a {
            display: flex;
            align-items: center;
            overflow: hidden;
         }

         .delete-button {
            @extend .ml-2;
            display: flex;
            visibility: hidden;

            @include media-breakpoint-down(xs) {
               visibility: visible;
            }
         }

         &:hover {
            .delete-button {
               visibility: visible;
            }
         }

         .file-icon {
            align-items: center;
            fill: $gray-700;
            @extend .mr-2;
            flex-shrink: 0;

            svg {
               height: 2rem;
               width: 2rem;
            }
         }
      }
   }
}

.picture-modal {
   max-width: calc(100% - (2 * #{$spacer}));
   display: flex;
   flex-direction: column;
   align-items: center;

   img {
      max-width: 100%;
      max-height: 75vh;
   }

   .spinner-container {
      display: flex;
      min-height: 400px;
      min-width: 400px;
      justify-content: center;
      align-items: center;

      .spinner-image {
         width: 100px;
         height: 100px;
      }
   }

   .rotate180 {
      transform: rotate(180deg);
   }

   .navigation-control {
      min-height: 48px;
      min-width: 48px;
   }

   .modal-content {
      width: auto;

      @include media-breakpoint-down(xs) {
         width: 100%;
      }
   }

   .modal-body {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;

      .save-indicator {
         position: absolute;
         top: $spacer / 4;
         right: calc(#{$spacer} + #{$spacer / 4});
      }
   }

   .modal-footer {
      flex-direction: column;
      justify-content: space-between;

      > div {
         display: flex;
         align-items: flex-end;
         margin: 0;
         width: 100%;
      }

      > div:first-child {
         @extend .mb-3;

         > :first-child {
            flex-grow: 1;
            @extend .mr-1;
         }
      }

      .picture-modal-delete-button {
         margin-left: auto;
      }
   }
}

.caption-edit-popover {
   .popover {
      width: 80rem;

      .popover-inner {
         @extend .p-1;
         display: flex;

         .btn {
            @extend .ml-1;
         }
      }
   }
}
