.evaluation {
   .evaluation-consideration-index {
      background-color: #631717;
      color: white;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-top: 24px;
      margin-left: 24px;
      margin-right: 24px;
   }

   .evaluation-row-left {
      display: flex;

      .form-group:last-child {
         flex-grow: 1;
         max-width: 360px;
      }
   }
}
