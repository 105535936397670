﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />

.site-dashboard {
  .agenda,
  .recent-activity {
    @extend .mb-3;

    > * {
      flex-shrink: 0;
    }

    .section-title {
      display: flex;
      align-items: center;
    }
  }

  .agenda {
    .agenda-item {
      &:hover {
        .btn.snooze {
          display: block;
          opacity: 1;
        }
      }
    }

    .follow-up {
      font-size: small;
    }

    a.description {
      display: block;
    }

    .description {
      @extend .mb-1;
    }

    .metadata {
      display: grid;
      grid-template-columns: 8em 8em auto;
      grid-column-gap: $spacer * 0.5;
      align-items: start;
      justify-items: flex-start;
      overflow: hidden;

      .record-type {
        grid-column: 1/2;
      }

      .status {
        grid-column: 2/3;
      }

      .details {
        color: $text-muted;
        font-size: 0.8em;
        grid-column: 3/4;
      }
    }

    .assets {
      align-items: baseline;
      margin-bottom: unset;
      display: flex;
      flex-wrap: wrap;

      > a {
        &:not(:last-child) {
          @extend .mr-2;
        }
      }

      > * {
        flex-shrink: 0;
      }
    }

    .avatar {
      $size: 1.5rem;
      height: $size;
      width: $size;
    }

    .extra-tags-indicator {
      font-size: small;
    }

    .btn.snooze {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
      display: none;
      opacity: 0;
      transition: all 1s ease;
    }
  }

  .recent-activity {
    .date-range {
      > div {
        display: flex;
        flex-wrap: wrap;

        > * {
          flex-grow: 0;
          flex-shrink: 0;
        }

        > :first-child {
          width: 11em;
          @extend .mr-3;
        }

        > :last-child {
          width: 8em;
        }
      }
    }

    .recent-activity-item {
      padding: 0;

      .item-container {
        padding: $list-group-item-padding-y $list-group-item-padding-x 0 $list-group-item-padding-x;

        &.no-details {
          padding-bottom: $list-group-item-padding-y;
        }
      }

      a {
        color: inherit;
        display: block;

        &:hover,
        &:active {
          text-decoration: none;
          background-color: $gray-200;
        }
      }

      .user-and-event {
        display: flex;
        align-items: center;

        > :not(:last-child) {
          @extend .mr-2;
        }

        .event-type {
          display: flex;
          align-items: center;

          > .icon,
          > .badge {
            @extend .ml-1;
          }

          > .icon {
            font-size: 1.2em;
          }

          > .badge {
            font-size: 1em;
          }
        }
      }

      .avatar {
        $size: 2rem;
        height: $size;
        width: $size;
        flex-shrink: 0;
        flex-grow: 0;
      }

      .event {
        color: $text-muted;
        font-size: small;
        flex-shrink: 1;
        flex-grow: 1;
      }

      .record-description {
        font-style: italic;
      }

      .title {
        @extend .mb-2;
      }

      .subtitle {
        display: flex;
        align-items: center;
        @extend .mt-1;

        > :not(:last-child) {
          @extend .mr-2;
        }

        .text {
          font-size: 0.8em;
        }
      }

      .date {
        font-style: italic;
      }

      .details-container {
        position: relative;
        @extend .mt-1;

        .details-toggle {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          .icon {
            fill: $text-muted;
          }

          &:hover,
          &:active {
            background-color: $gray-200;
          }
        }

        .details {
          padding: 0 $list-group-item-padding-x $list-group-item-padding-y $list-group-item-padding-x;
          color: $text-muted;
          font-size: small;
        }
      }
    }
  }

  .widgets-container {
    @extend .mb-5;

    iframe {
      border-style: none;
      background-color: white;
    }
  }
}

.customers-and-sites {
  .customer-selector {
    @extend .mb-4;
  }

  .site-list {
    @extend .mb-4;
  }

  .team-member {
    display: flex;
    align-items: center;
    font-size: $font-size-base;

    .avatar {
      $size: 2.5rem;
      height: $size;
      width: $size;
      overflow: hidden;
      @extend .mr-2;
    }
  }

  .customer-logo {
    height: 5rem;
    @extend .mt-2;
    @extend .mb-3;
  }

  .header {
    font-size: $h5-font-size;
    font-family: $headings-font-family;
    font-weight: 700;
    display: flex;
    @extend .mb-2;

    .btn {
      margin-left: auto;
    }
  }

  .area {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      @extend .mb-1;
    }

    :first-child {
      flex-grow: 1;
    }

    .delete-button {
      @include hover-button(1.5em, theme-color-level("danger", $alert-bg-level));
      @extend .ml-2;
      display: flex;
      visibility: hidden;
    }

    &:hover {
      .delete-button {
        visibility: visible;
      }
    }
  }
}
