﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />

.search-bar {
   display: flex;
   align-items: center;
   font-family: $font-family-base;
   position: relative;
   width: 550px;

   @include media-breakpoint-down(lg) {
      width: 400px;
   }

   @include media-breakpoint-down(md) {
      width: 100%;
   }

   .icon {
      height: 1.5em;
      width: 1.5em;
   }

   .search-box {
      flex-shrink: 1;
      flex-grow: 1;
      flex-wrap: nowrap;

      .icon {
         fill: $text-muted;
      }

      .input-group-text {
         background-color: #eeeeee;
         padding: $btn-padding-y;
         border-color: white;

         &.prepend {
            border-right: none;
         }

         &.append {
            border-left: none;
         }
      }

      input {
         height: auto; // Corrects fixed input height coming from Bootstrap
         background-clip: border-box; // fixes thin blue lines that appear at top and bottom of input at certain zoom levels
         background-color: #eeeeee;
      }

      .form-control {
         border-left: none;
         border-color: white;
         border-right: none;

         &:focus {
            box-shadow: none;
         }
      }
   }

   .global-search-toggle {
      @extend .ml-1;
      white-space: nowrap;
      flex-shrink: 0;

      @include media-breakpoint-down(xs) {
         display: none;
      }
   }

   input {
      &::-ms-clear {
         display: none;
      }

      flex-grow: 1;
      flex-shrink: 1;
   }

   .search-results {
      font-size: $font-size-base;

      @include media-breakpoint-down(md) {
         font-size: $font-size-sm;
      }

      @include media-breakpoint-down(sm) {
         font-size: $font-size-sm * 0.9;
         right: -80px;
      }

      @include media-breakpoint-down(xs) {
         min-width: 20em;
         max-width: calc(100vw - 10px);
         right: -122px;
      }

      overflow-y: auto;
      position: absolute;
      top: calc(100% + 1px);
      right: 0;
      z-index: 1000;
      width: 550px;
      max-height: 90vh;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
   }

   .search-result {
      background-color: white;
      color: color-yiq(white);
      padding: 1em;
      border-bottom: 1px solid $gray-200;
      cursor: pointer;
      overflow: hidden;

      &:hover {
         background-color: $gray-200;
      }

      &:active {
         background-color: darken($gray-200, 20%);
      }

      mark {
         color: inherit;
         background-color: transparent;
         padding: 0;
         text-decoration: underline;
         text-decoration-color: $cyan;
      }

      .identifier {
         font-weight: 500;
         font-size: 1em;

         .tag {
            font-weight: bold;
            font-size: 1.4em;
         }
      }

      .parent {
         display: flex;
         flex-direction: column;
         align-items: flex-end;
         color: $text-muted;
         font-size: 0.8em;
         float: right;
         max-width: 50%;
         line-height: 1.3;
         @extend .mb-1;

         > * {
            max-width: 100%;
         }
      }

      .descriptor {
         display: flex;
         align-items: center;
         color: $text-muted;
         @extend .mb-1;
         font-size: 0.8em;

         .badge {
            font-size: 1em;
         }

         > :not(:last-child) {
            @extend .mr-2;
         }
      }

      .shrinkable {
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
         flex-shrink: 1;
      }

      .content {
         clear: both;
         font-size: 0.8em;
         color: $text-muted;
      }
   }

   .record-search-result {
      .identifier {
         font-size: 1em;
      }
   }
}
