@import "ScopeCard";
@import "planning";
@import "milestones";
@import "evaluation";

.record-outage-details {
   .record-outage-details-nav {
      width: 100%;
      margin: 1em;
      .record-outage-details-tab {
         padding: 0 2rem;

         &:first-child,
         &:last-child {
            padding: 0;
         }

         .wrapper {
            width: 100%;
            display: flex;
            position: relative;

            .bottom-underline {
               background: $gray-400;
               width: 100%;
               height: 3px;
               border-radius: 3px;
               position: absolute;
               bottom: -6px;
            }

            .icon {
               fill: $gray-500;
               font-size: 1.2em;
               line-height: 1;
               svg {
                  background-color: $gray-200;
               }
            }
            .title {
               flex-grow: 1;
               margin-left: 0.5em;
               font-weight: normal;
            }
         }
         &.is-selected {
            .bottom-underline {
               background: $black;
            }
         }
         &:not(.is-selected) {
            .title {
               color: $gray-700;
            }
         }
         &.is-current {
            .icon {
               fill: $status-in-progress;
            }
         }
         &.is-completed {
            .icon {
               fill: $status-completed;
            }
         }
      }
   }
}

.incorrect-outage-phase {
   display: flex;
   width: 100%;
   height: 200px;
   justify-content: center;
   align-items: center;
   color: $gray-700;
}

.outage-modal {
   input.form-control {
      margin: 2px 0px;
   }
}
