﻿/// <reference path="..\..\..\..\node_modules\bootstrap\scss\bootstrap.scss" />
/// <reference path=".\_variable-overrides.scss" />

.btn {
   display: inline-flex;
   align-items: center;
   font-family: $font-family-base;

   .icon {
      fill: white;
   }

   .icon + * {
      @extend .ml-1;
   }

   * + .icon {
      @extend .mr-1;
   }

   &.x-small {
      font-size: x-small;
      padding: 0.2rem 0.3rem;
      line-height: 1;
   }

   &.btn-outline-secondary {
      .icon {
         fill: theme-color("secondary");
      }
   }
}
