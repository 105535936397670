.milestones {
   table {
      border-collapse: collapse;

      td,
      th {
         padding: 0;
      }
   }

   .milestones-row .cell {
      display: flex;
      align-items: center;

      &.label {
         padding-right: 32px;
         color: $gray-700;
      }
      &.expected {
         background: #fcf9f6;
         margin-right: 20px;
         padding: 0px 16px;

         &.header {
            border-top: solid 3px #f29f3e;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
         }
      }
      &.actual {
         padding: 0px 16px;
         margin-right: 20px;
         background: #e8eef7;

         &.header {
            border-top: solid 3px #004b8d;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
         }
      }

      &.difference {
         color: #f29f3e;
         font-weight: bold;
         .error {
            color: $red;
         }
      }
   }
   .waiting-icon {
      fill: $gray-600;
      width: 24px;
      height: 24px;
   }
   .in-progress-icon {
      fill: #3182ce;
      width: 24px;
      height: 24px;
   }
   .completed-icon {
      fill: $green;
      width: 24px;
      height: 24px;
   }
}
