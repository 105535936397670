﻿@import "outage";

.record-type {
   text-transform: uppercase;
   font-weight: 500;
}

.follow-up-input {
   input {
      @extend .mb-2;
   }
}

.record-screen {
   .decorators {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      > :not(:last-child) {
         @extend .mr-3;
      }

      .record-id {
         white-space: nowrap;
         font-size: small;
         color: $text-muted;
      }

      .assets-right {
         margin-left: auto;
         font-size: 80%;

         label {
            @extend .mr-1;
         }

         @include media-breakpoint-down(xs) {
            font-size: 60%;
            margin-left: 0;
            margin-top: 0.5em;
         }
      }
   }

   .record-screen-title {
      display: flex;

      .screen-title {
         font-size: $h2-font-size;
         flex-grow: 1;

         @include media-breakpoint-down(xs) {
            font-size: $h4-font-size;
         }

         .badge {
            font-size: $h6-font-size;
         }
      }

      .icon-and-title {
         margin-bottom: 0.5em;

         .invalid-feedback {
            display: none;
         }

         .inline-suggest {
            width: 100%;
            font-size: $h2-font-size;
            font-family: unset;
            line-height: 1.1em;

            @include media-breakpoint-down(xs) {
               font-size: $h4-font-size;
            }

            input {
               font-family: inherit;
               font-size: inherit;
               line-height: inherit;
               border-color: $input-border-color;
               padding: $input-padding-y $input-padding-x;

               &:focus {
                  border-color: $input-focus-border-color;
                  outline: none;
               }
            }

            input + div {
               font-family: inherit;
               font-size: inherit;
               line-height: inherit;
               padding: $input-padding-y $input-padding-x;
               top: 0;
               left: 0;
               border: $input-border-width solid transparent;
               /*
                * wtf - why do we display input + div with the same input, then try to stack them on top
                * of each other and line up the text so you can't see it??!?!
                *
                * I'm just going to hide this and see who complains.
                *
                * Matt - 11/22/2022
                */
               display: none;
            }

            &.is-invalid + .invalid-feedback {
               display: block;
            }

            &.is-invalid {
               input {
                  border-color: $form-feedback-invalid-color;
               }
            }
         }

         .form-control {
            &.is-invalid + .invalid-feedback {
               display: block;
            }
         }
      }

      .commands {
         display: grid;
         grid-template-rows: auto auto;
         grid-row-gap: $spacer * 0.5;
         align-self: flex-start;
      }

      .invalid-feedback {
         display: block;
      }
   }

   .record-details {
      .row:last-child {
         .form-group {
            margin-bottom: 0;
         }
      }
   }

   .asset-select .react-select__multi-value__label {
      @extend .tag;
      font-size: 110%;
      padding-top: 1px;
      padding-bottom: 1px;
   }

   .record-recommendations {
      .title {
         @extend .mr-3;
      }

      .section-title {
         align-items: baseline;
         flex-wrap: wrap;

         .gap {
            flex-grow: 1;
            flex-shrink: 1000;
         }

         .recommendation-select {
            flex-basis: 25em;
            flex-shrink: 1;

            @include media-breakpoint-down(sm) {
               flex-basis: 15em;
            }

            @include media-breakpoint-down(xs) {
               margin-top: 0.5rem;
            }
         }
      }

      .recommendation-display {
         .recommendation-content {
            flex-grow: 1;
            margin-bottom: 0;
            width: 100%;

            .form-control {
               @extend .mb-2;
            }
         }

         .status-container {
            display: flex;
            align-items: center;

            .status-select {
               width: 14em;
               @extend .ml-2;
            }
         }

         .status-and-actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .button-container {
               @include media-breakpoint-down(xs) {
                  margin-top: 0.5em;
               }
            }
         }
      }
   }

   .record-history {
      .section-title {
         .checkbox {
            @extend .ml-4;
            font-family: $font-family-base;
            text-transform: initial;
            font-weight: 400;
         }
      }

      .comment-entry {
         .form-group {
            display: flex;
            flex-direction: column;

            .controls {
               align-self: flex-end;
               @extend .mt-2;
               display: flex;
               align-items: center;

               > :not(:last-child) {
                  @extend .mr-2;
               }
            }
         }
      }

      .history-event-display {
         @extend .mb-3;
         display: flex;
         align-items: flex-start;

         .avatar {
            height: 1.5rem;
            width: 1.5rem;
            @extend .mr-2;
            flex-shrink: 0;
         }

         .history-event-text {
            flex-grow: 1;

            .resolution-container {
               @extend .mr-2;

               .star-icon-container {
                  position: relative;
                  bottom: 2px;
               }

               .resolution {
                  font-weight: bold;

                  @extend .ml-1;
               }
            }
         }

         .meta {
            color: $text-muted;
            font-size: $font-size-sm;

            .posted-date {
               font-style: italic;
               font-size: small;
            }
         }

         .hidden-btn {
            border: 1px solid transparent;
            background: transparent;
            @extend .ml-2;
            @extend .p-1;
            opacity: 0;

            &.active {
               opacity: 1;
            }

            .icon {
               fill: $yiq-text-dark;
               @extend .m-0;
            }
         }

         &:hover {
            .hidden-btn {
               border-color: $secondary;
               opacity: 1;
            }
         }
      }
   }
}

.asset-health {
   .asset-health-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
         fill: $gray-700;
         height: 1.5rem;
         width: 1.5rem;
      }

      #mobile-info-icon,
      #non-mobile-info-icon {
         cursor: pointer;
      }
   }

   .card-subtitle {
      @extend .mt-0;
   }

   .overall {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .overall-score {
         font-size: $font-size-base * 4;
         font-weight: 400;
         line-height: 1;
         text-shadow: 1px 1px 1px $gray-700;

         &.grey {
            color: $gray-500;
         }

         &.green {
            color: $green;
         }

         &.yellow {
            color: $yellow;
         }

         &.orange {
            color: $orange;
         }

         &.red {
            color: $red;
         }
      }
   }
}

.report-card {
   table {
      margin-bottom: 0;
   }

   tr:first-child th {
      border: none;
   }

   th {
      text-align: center;
      font-weight: 400;
      font-size: small;
   }

   td {
      vertical-align: middle;
      font-size: small;
   }

   .gap {
      @extend .my-4;
   }

   .traffic-light {
      border: 1px solid $gray-200;
      margin-left: auto;
      margin-right: auto;

      &:hover:not(.locked) {
         border-color: $gray-500;
      }

      &:not(.locked) {
         cursor: pointer;
      }
   }
}

.traffic-light {
   $traffic-light-size: 1.5rem;
   height: $traffic-light-size;
   width: $traffic-light-size;
   border-radius: 50%;

   @include media-breakpoint-down(lg) {
      $traffic-light-size: 1.25rem;
      height: $traffic-light-size;
      width: $traffic-light-size;
   }

   &.red {
      background-color: $red;
      border-color: $red;
   }

   &.orange {
      background-color: $orange;
      border-color: $orange;
   }

   &.yellow {
      background-color: $yellow;
      border-color: $yellow;
   }

   &.green {
      background-color: $green;
      border-color: $green;
   }
}

.visual-integrity {
   .badge {
      white-space: normal;
      color: white;
      font-family: $font-family-sans-serif;
      width: 100%;
      justify-content: center;
   }
}

.asset-link-list,
.record-link-list {
   display: flex;
   flex-flow: row wrap;

   a {
      margin-right: $spacer * 0.5;
   }

   @supports (display: grid) {
      display: grid;
      grid-gap: $spacer * 0.5;
      grid-template-columns: repeat(auto-fill, minmax(10em, 0fr));

      a {
         margin-right: 0;
      }
   }
}
